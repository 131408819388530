import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

import footerStyles from "./footer.module.scss";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
        }
      }
    }
  `);

  return (
    <footer className={footerStyles.footer}>
      <p className={footerStyles.links}>
        {data.site.siteMetadata.author}, ©2020 |
        {" "}<Link to="/privacypolicy">Privacy Policy</Link> |
        {" "}<Link to="/support">Support</Link>
      </p>
    </footer>
  );
};

export default Footer;

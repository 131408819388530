import React from "react";
import { Link } from "gatsby";
import logo from "../images/smads_logo.jpg";

import headerStyles from "./header.module.scss";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Header = () => {
  return (
    <header className={headerStyles.header}>
      <Link to="/">
        <img className={headerStyles.logo} src={logo} alt="SMADS logo" />
      </Link>
      <nav>
        <ul className={headerStyles.navList}>
          <li>
            <Link
              className={headerStyles.navItem}
              activeClassName={headerStyles.activeNavItem}
              to="/"
            >
              Home
            </Link>
          </li>
          <li>
            <AnchorLink
              className={headerStyles.navItem}
              activeClassName={headerStyles.activeNavItem}
              to="/#about"
            >
              About
            </AnchorLink>
          </li>
          <li>
            <Link
              className={headerStyles.navItem}
              activeClassName={headerStyles.activeNavItem}
              to="/team"
            >
              Team
            </Link>
          </li>
          <li>
            <Link
              className={headerStyles.navItem}
              activeClassName={headerStyles.activeNavItem}
              to="/publications"
            >
              Publications
            </Link>
          </li>
          <li>
            <Link
              className={headerStyles.navItem}
              activeClassName={headerStyles.activeNavItem}
              to="/media"
            >
              Media
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
